import React from 'react'
import jwtDecode from 'jwt-decode'
import { GoogleLogin } from '@react-oauth/google'
import SignInWithAppleButton from '../../misc/SignInWithAppleButton'

function SelectExternalAccountComponent(props) {
  const {
    title, externalAccount, setExternalAccount, externalAccountType,
  } = props

  function onGoogleSuccess(credentialResponse) {
    const idToken = credentialResponse.credential
    const userInfo = jwtDecode(idToken)
    setExternalAccount({
      idToken,
      name: userInfo.name,
      email: userInfo.email,
    })
  }

  function appleCallback(data) {
    if (data.authorization) {
      const { user } = data
      setExternalAccount({
        idToken: data.authorization.id_token,
        name: user && `${user.name.firstName} ${user.name.lastName}`,
        email: user && user.email,
      })
    }
  }

  return (
    <div className='accountAuthentication-selectExternalAccount'>
      {!externalAccount && (
        <React.Fragment>
          <div className='accountAuthentication-selectExternalAccount-title'>
            {title}
          </div>
          {externalAccountType === 'google' && (
            <div className='accountAuthentication-selectExternalAccount-buttonContainer'>
              <GoogleLogin
                width='230px'
                logo_alignment='center'
                onSuccess={(credentialResponse) => onGoogleSuccess(credentialResponse)}
                containerProps={{ allow: 'identity-credentials-get' }}
                use_fedcm_for_prompt
              />
            </div>
          )}
          {externalAccountType === 'apple' && (
            <div className='accountAuthentication-selectExternalAccount-buttonContainer'>
              <SignInWithAppleButton
                callback={(data) => appleCallback(data)}
                redirectPath='/account'
              />
            </div>
          )}
        </React.Fragment>
      )}
      {externalAccount && (
        <React.Fragment>
          {externalAccount.name && externalAccount.email && (
            <React.Fragment>
              <div className='accountAuthentication-selectExternalAccount-title'>
                Selected account
              </div>
              <div className='accountAuthentication-selectedExternalAccountCard'>
                <div className='accountAuthentication-selectedExternalAccountCard-card'>
                  <div className='accountAuthentication-selectedExternalAccountCard-card-details'>
                    <div className='accountAuthentication-selectedExternalAccountCard-card-details-name'>
                      {externalAccount.name}
                    </div>
                    <div className='accountAuthentication-selectedExternalAccountCard-card-details-email'>
                      {externalAccount.email}
                    </div>
                  </div>
                </div>
              </div>
            </React.Fragment>
          )}
          {!externalAccount.name && !externalAccount.email && (
            <div className='accountAuthentication-selectExternalAccount-title--noDetails'>
              Account selected
            </div>
          )}
        </React.Fragment>
      )}
    </div>
  )
}

export default SelectExternalAccountComponent
